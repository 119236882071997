<template>
    <div class="curve-container">
        <div class="curve-sidebar">
            <div class="curve-operation-header">设备列表</div>
            <div class="curve-sidebar-body">
                <el-scrollbar class="aside-scrollbar" wrap-class="aside-scrollbar-wrapper" style="height: 100%;">
                    <el-tree ref="sidebarTree" :data="devicesData" :props="defaultProps" :check-on-click-node="true"
                        class="deviceTree" highlight-current show-checkbox node-key="nodeid" :render-after-expand="false"
                        @check-change="onCheckChange">
                    </el-tree>
                </el-scrollbar>
            </div>
        </div>
        <div class="curve-content">
            <div class="container-header">
                <div class="top-actions-group">
                    <div class="action-left">
                        <el-form class="geology-form">
                            <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-"
                                start-placeholder="开始日期" end-placeholder="结束日期" size="medium"
                                style="width: 240px; margin-left: 10px" value-format="yyyy-MM-dd" @change="dateChange">
                            </el-date-picker>
                        </el-form>
                    </div>
                    <div class="action-right">
                        <el-button size="small" @click="onClear">全部清除</el-button>
                        <el-button size="small" type="primary" icon="el-icon-search" @click="getDevicesData">查询
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="curve-content-body">
                <el-row :gutter="20">
                    <template v-for="(deviceSensorData, index) in  selectedDevicesData ">
                        <template v-if="deviceSensorData.length > 1">
                            <el-col v-for="(deviceData, index2) in deviceSensorData" :lg="24" :xl="12"
                                style="margin-bottom:10px;">
                                <div class="device-name">{{
                                    selectedDevices[index].MonitorName + ' - ' + selectedDevices[index].DeviceName }}</div>
                                <!-- 雨量计 -->
                                <template v-if="charLineMin(selectedDevices[index]) == 0.5">
                                    <chart-bar :id="'chartBarData' + index + '-' + index2" class="device-chart"
                                        :yname="deviceData.unit" :min-interval="charLineMin" :data="deviceData
                                            .chartGnnsData
                                            " :option="{ dataZoom: { type: 'slider' } }"></chart-bar>
                                </template>
                                <!-- 测斜仪 -->
                                <chart-tilt :id="'chartTiltData' + index + '-' + index2" class="device-chart-tilt"
                                    :min-interval="charLineMin(selectedDevices[index])" :data="deviceData.chartGnnsData
                                        " :option="{ dataZoom: { type: 'slider' } }"
                                    v-else-if="charLineMin(selectedDevices[index]) == 2"></chart-tilt>
                                <!-- 其他 -->
                                <template v-else>
                                    <chart-line :id="'chartLineData' + index + '-' + index2" class="device-chart"
                                        :device-type="selectedDevices[index].DeviceType" :yname="deviceData.unit"
                                        :min-interval="charLineMin(selectedDevices[index])" :data="deviceData.chartGnnsData
                                            " :option="{ dataZoom: { type: 'slider' } }"></chart-line>
                                </template>
                            </el-col>
                        </template>
                        <el-col v-else :lg="24" :xl="12" style="margin-bottom:10px;">

                            <div class="device-name">{{
                                selectedDevices[index].MonitorName + ' - ' + selectedDevices[index].DeviceName }}</div>

                            <template v-if="deviceSensorData.length > 0">
                                <!-- 雨量计 -->
                                <template v-if="charLineMin(selectedDevices[index]) == 0.5">
                                    <chart-bar :id="'chartBarData' + index" class="device-chart"
                                        :yname="deviceSensorData.length > 0 ? deviceSensorData[0].unit : ''"
                                        :min-interval="charLineMin(selectedDevices[index])"
                                        :data="deviceSensorData[0].chartGnnsData"
                                        :option="{ dataZoom: { type: 'slider' } }"></chart-bar>

                                </template>
                                <!-- 测斜仪 -->
                                <chart-tilt :id="'chartTiltData' + index" class="device-chart-tilt"
                                    :min-interval="charLineMin(selectedDevices[index])" :data="deviceSensorData[0].chartGnnsData
                                        " v-else-if="charLineMin(selectedDevices[index]) == 2"
                                    :option="{ dataZoom: { type: 'slider' } }"></chart-tilt>
                                <!-- 其他 -->
                                <template v-else>
                                    <chart-line :id="'chartLineData' + index" class="device-chart"
                                        :device-type="selectedDevices[index].DeviceType"
                                        :yname="deviceSensorData.length > 0 ? deviceSensorData[0].unit : ''"
                                        :min-interval="charLineMin(selectedDevices[index])"
                                        :data="deviceSensorData[0].chartGnnsData"
                                        :option="{ dataZoom: { type: 'slider' } }"></chart-line>
                                </template>
                            </template>
                            <div v-else class="no-device-data" style="text-align: center">
                                暂无数据
                            </div>


                        </el-col>
                    </template>
                </el-row>
                <div v-if="selectedDevicesData.length == 0" class="no-device-data" style="text-align: center">
                    暂无数据
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getMonitorDeviceList } from "@/api/user";
import { getEasyDeviceLineDataList } from "@/api/device";

import chartLine from "./components/chart-line";
import chartBar from "./components/chart-bar";
import chartTilt from "./components/chart-tilt";

export default {
    data() {
        return {
            devicesData: [],
            defaultProps: {
                children: "children",
                label: "label",
            },
            filterDaterange: "",
            listfilter: {
                fstarttime: "",
                fendtime: "",
            },
            selectedDevices: [],//选中的设备
            selectedDevicesData: [],
        };
    },
    computed: {
    },
    components: {
        chartLine,
        chartBar,
        chartTilt,
    },
    mounted() {
        getMonitorDeviceList().then((res) => {
            if (res.code == 0) {
                this.devicesData = res.data.map(item => {
                    item.label = item.hiddanger.DangerName;
                    item.nodeid = 'hiddanger_' + item.hiddanger.Id;
                    item.type = 'hiddanger';
                    item.children = item.monitorlist.map(monitor => {
                        monitor.label = monitor.MonitorName;
                        monitor.nodeid = 'hiddanger_' + monitor.Id;
                        monitor.type = 'hiddanger';
                        monitor.children = monitor.deviceList.map(device => {
                            device.label = device.DeviceName;
                            device.MonitorName = monitor.MonitorName;
                            device.nodeid = device.Id;
                            device.type = 'device';
                            return device;
                        })
                        return monitor;
                    })
                    return item;
                })
            }
        });

    },
    beforeDestroy() {

    },
    methods: {

        onCheckChange(node, checked) {
            if (node.type == 'device') {
                if (checked) {
                    //选中
                    this.selectedDevices.push(node);
                    this.getDeviceData(node.Id, node);
                } else {
                    let index = this.selectedDevices.findIndex(item => item.Id == node.Id);
                    this.selectedDevices.splice(index, 1)
                    this.selectedDevicesData.splice(index, 1)
                }
            }

        },

        dateChange(value) {
            if (value) {
                var newdate = [];
                for (
                    let index = 0;
                    index < this.filterDaterange.length;
                    index++
                ) {
                    const element = this.filterDaterange[index];
                    newdate.push(element);
                }
                this.listfilter.fstarttime = newdate[0];
                this.listfilter.fendtime = newdate[1];
                this.getDevicesData();
            } else {
                this.listfilter.fstarttime = "";
                this.listfilter.fendtime = "";
                this.getDevicesData();
            }
        },
        getDevicesData() {
            //获取所有设备数据
            if (this.selectedDevices.length == 0) {
                return;
            }
            let index = 0;
            let that = this;
            this.selectedDevicesData = [];
            function getData() {
                that.getDeviceData(that.selectedDevices[index].Id, that.selectedDevices[index], () => {
                    index = index + 1;
                    if (index < that.selectedDevices.length) {
                        getData();
                    }
                })
            }
            getData();
        },
        getDeviceData(deviceid, deviceData, success) {
            //获取单个设备数据
            let that = this;
            getEasyDeviceLineDataList({
                ...this.listfilter,
                deviceid: deviceid
            }).then(res => {


                var deviceSensorData = [];
                if (deviceData.DeviceType == "测斜仪") {
                    deviceSensorData = [
                        {
                            chartGnnsData: {
                                series: res.data,
                            },
                        },
                    ];
                } else {
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        var xAxis = element.x;
                        var xAxisAngle = '';
                        var series = [];
                        var angleseries = [];
                        var zxrateseries = '';
                        for (let j = 0; j < element.list.length; j++) {
                            const item = element.list[j];
                            series.push({
                                name: item.name + '(' + item.unit + ')',
                                type: "bar",
                                data: item.y,
                                rawdata: item,
                            });
                            if (deviceData.DeviceType == "雨量计") {
                                series.push({
                                    name: "累计雨量(mm)",
                                    type: "line",
                                    data: item.rainfalls,
                                });
                            }

                            xAxisAngle = item.ratex;
                            angleseries.push(
                                {
                                    name: "变形速率",
                                    data: item.rate,
                                },
                                {
                                    name: "切线角",
                                    data: item.angle,
                                }
                            );

                            if (item.zxrate.length > 0) {
                                var mintime = item.mintime.split(',').length > 1 ? item.mintime.split(',')[1] : '';
                                var maxtime = item.maxtime.split(',').length > 1 ? item.maxtime.split(',')[1] : '';
                                zxrateseries = {
                                    name: '速度倒数',
                                    xaxis: item.zxtime,
                                    data: item.zxrate,
                                    mintime: mintime,
                                    maxtime: maxtime,
                                };
                            }
                        }
                        deviceSensorData.push({
                            sensor: element.sensor,
                            name: element.list.length > 0 ? element.list[0].name : '传感器' + element.sensor,
                            unit:
                                element.list.length > 0
                                    ? element.list[0].name +
                                    "(" +
                                    element.list[0].unit +
                                    ")"
                                    : "",
                            chartGnnsData: {
                                xAxis: xAxis,
                                series: series,
                                list: element.list,
                            },
                            chartAngleData: {
                                xAxis: xAxisAngle,
                                series: angleseries,
                                list: element.list,
                            },
                            chartZxrate: zxrateseries
                        });
                    }

                }

                this.selectedDevicesData.push(deviceSensorData);

                success && success();
            })
        },

        charLineMin(deviceData) {
            if (deviceData.DeviceType == "裂缝计") {
                return 10;
            } else if (deviceData.DeviceType == "雨量计") {
                return 0.5;
            } else if (deviceData.DeviceType == "测斜仪") {
                return 2;
            } else if (deviceData.DeviceType == "GNSS") {
                return 10;
            } else {
                return 1;
            }
        },
        onClear() {
            var nodes = this.$refs.sidebarTree.getCheckedNodes()
            nodes.forEach(element => {
                this.$refs.sidebarTree.setChecked(element.nodeid, false);
            });
            this.selectedDevicesData = [];

        }
    },
};
</script>

<style lang="scss" scoped>
.curve-container {
    height: calc(100vh - 96px);
    box-sizing: border-box;
    margin: -20px;
    display: flex;
    align-items: stretch;
    overflow: hidden;

    .curve-sidebar {
        width: 240px;
        border-right: 1px solid #2696c2;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .tree-scrollbar {
        height: 100%;
    }

    .curve-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
}

.curve-sidebar-body,
.curve-content-body {
    flex: 1;
    overflow-y: auto;

}

.curve-content-body {
    padding: 10px 20px;
    background-color: #fff;

    .device-name {
        font-size: 15px;
    }
}

.curve-operation-header {
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px rgba(0, 165, 254, 0.5);
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    color: #32C5FF;
    font-weight: 700;
}

.container-header {
    padding: 10px;

    .top-actions-group {
        margin: 0;
    }
}

.deviceTree {
    background: transparent;
    color: #fff;

    ::v-deep {
        .el-tree-node.is-current>.el-tree-node__content {
            background: linear-gradient(270deg, #20fefd, #3959ff);
        }

        .el-tree-node__content {
            height: 36px;

            &:hover {
                background-color: rgba(80, 252, 252, 0.1);
            }
        }

        .el-tree-node__children {
            overflow: visible;
        }

        .tree-node-icon {
            font-size: 14px;
            margin-right: 3px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -4px;
        }
    }
}

.no-device-data {
    height: 140px;
    line-height: 140px;
    font-size: 16px;
}

.device-chart {
    width: 100%;
    height: 200px;
    line-height: 60px;
    color: #fff;

    .el-loading-mask {
        background: transparent;
    }
}

.device-chart-tilt {
    width: 100%;
    height: 620px;
    line-height: 60px;
}
</style>